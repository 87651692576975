import React,{useState,useEffect,useCallback} from 'react';
import {Modal,Form,InputGroup} from 'react-bootstrap';
import ModalImg from '../../../assets/modal-banner.jpg';
import {FaUser,FaPhoneAlt,FaEnvelope,FaEdit} from 'react-icons/fa';
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import './RequestForm.css';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_q2onGdIpd5vItAyieytfr"); 


//phone number regExp
const PhoneSchema=  /^[6789]\d{9}$/;
//validation schema for enquiry form
const schema = yup.object().shape({
  name: yup.string().max(20).required("Please enter your name"),
  number: yup.string().matches(PhoneSchema,'Please enter a valid number').required("Contact number is required"),
  email: yup.string().email("Please enter a valid Email address").required("Please enter your email"),
  messagetext:yup.string().required("Describe your message")
});

const RequestForm = () => {
 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
 

  const [snack,showSnack]=useState(false);
  const [showErr,setShowErr]= useState(false);
   const { register, handleSubmit,errors } = useForm({ mode: 'onSubmit',
   reValidateMode: 'onChange',
   defaultValues: {},
   // Note: will be deprecated in the next major version with validationResolver
   resolver: yupResolver(schema),
   validateCriteriaMode: "firstErrorDetected",
   submitFocusError: true,
   nativeValidation: false, // Note: version 3 only
  }) 


    const onSubmit = (data, r) => {
        r.preventDefault();
        const templateId = 'template_kuxu8zv';
        const serviceID = 'service_j2wqtjs';
        sendFeedback(serviceID, templateId, { from_name: data.name,contact_number:data.number,reply_to: data.email, message_html: data.messagetext})
        r.target.reset();
    }
    const sendFeedback = (serviceID, templateId, variables) => {
        emailjs.send(
            serviceID, templateId,
            variables
        ).then(res => {
           setShow(false);
          showSnack(true);
          setTimeout(() => {
            showSnack(false);
          },3000);
        })
            .catch(err =>{
              setShowErr(true);
              setTimeout(() => {
                setShowErr(false);
              }, 5000);
            })
    } 
    const Popup= useCallback(
      () => {
        setTimeout(() => {setShow(true);}, 15000);
      },[setShow])
useEffect(()=>{
    window.addEventListener('load',Popup);
  return ()=> window.removeEventListener('load',Popup);
})


    return (
        <>
          <Modal className="request-modal mt-3"
        show={show} 
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <div className="modal-img-box">
              <img src={ModalImg} width="100%" height="183" alt=""/>
              <div className="modal-head-text">
                "Time to Accelerate You Career"
              </div>
          </div>
        </Modal.Header>
        <Modal.Body>
         <div className="p-2">
         <Form  onSubmit={handleSubmit(onSubmit)}> 
         <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-box"><FaUser/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control  placeholder="Name" type="text" name="name" ref={register} />
        </InputGroup>
  
        <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-box"><FaPhoneAlt/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control  placeholder="Mobile Number" type="number" name="number" ref={register}  />
        </InputGroup>

        <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-box"><FaEnvelope/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control placeholder="Email" type="email" name="email" ref={register}  />
        </InputGroup>

        <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-box"><FaEdit/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control  placeholder="Your Message" type="textarea" name="messagetext" ref={register}  />
        </InputGroup>
         <div className="error-box">
         {errors.name &&<p><span>*</span>{ errors.name.message}</p>}
         {errors.number &&<p><span>*</span>{errors.number.message}</p>}
        {errors.email && <p><span>*</span>{errors.email.message} </p>}
            {errors.messagetext && <p><span>*</span>{errors.messagetext.message}</p>}
         </div> 
         <div className="text-center">
           <div className="mt-1 mb-1">
             { showErr ? 
             <h5><b>Something went wrong,<br/> Please try after sometime</b></h5> : null
              }
           </div>
         <input className="submitBtn" type="submit"  />
         </div>
         </Form>
         </div>
        </Modal.Body>
      </Modal> 
        <div className={snack ? "snackbar show" : "snackbar"}>
              Your Response is Submitted!
        </div>
        </>
    )
}

export default RequestForm;
