import React from 'react';
import { Accordion, Card, Container} from "react-bootstrap";
import './Contact.css';

const FaqData=[
    
    {
      id:"1",
      qa:"Will participant be able to design, implement and deploy IoT services, devices, etc at the end of the Internet of Things Training?",
      ans:"Absolutely yes, if you see our End-To-End 60 Hours IoT Training module in which we cover more than 5 Industrial IOT use case using open source & industrial IOT cloud platform after which participant would be able to build their own any IOT application or solution"
    },
    {
      id:"2",
      qa:"What next can I learn after a succesfull IOT Training completion ?",
      ans:"With as many as devices connected to the internet, there is a potential increase in the data that gets generated as well. This drastically increases the demand for Data Analysts to focus on analysing the generated data in real time to extract important insights of these Data. Thereby making, Internet of Things, Big Data and cloud mutually inter-connected to each other"
    },
    {
      id:"4",
      qa:"What is course duration of IOT(Internet Of Things)training ?",
      ans:"IoT Classroom Training in Coimbatore: • Regular classroom based training: 4 weeks, 90 minutes of Practical + Theory session per day. • Fast Track (1-1 : Internet of Things (IoT) crash course in Coimbatore ) : 15 days. • Choose your options for week day or week end classroom training."
    },
    {
      id:"5",
      qa:"Will you guide me for Interview Preparation ?",
      ans:`• Assessments - Our training pattern includes conducting frequent assessments to understand your technical competence & brief your areas of improvement, during the tenure of the course.
           •Interview Questionnaire - At here, a dedicated team is available to collate the frequently asked questions in the Top MNCs, and will share the questionnaire with our candidates.
           • Mock Interviews - At the end of the course, a team of highly qualified real-time IT technical experts from the industry assess your knowledge on the language, by conducting interviews, and share the feedback so you can crack the interview with confidence & at ease.    
           •Resume Building Services – Expert trainers guide our candidates on the Resume preparation which will profoundly help you put your career on track. 
      `
    },
    {
      id:"6",
      qa:"What are the Job roles available in IOT ?",
      ans:`There are some prominent job roles in the field of Internet of Things. Here are the few lists
          •IOT Developer  
          •IOT Architect 
          •IOT Product Manager  
          •Robot Co-Ordinator  
          •Industrial data-scientists  
          •Industrail Ui/Ux designer  
          •Industrail Engineer  
          •Industrial Networking Engineer and more.`
    },
    {
    id:"7",
    qa:"Will I be getting Take away hardware kit or what if do not want hardware kit ?",
    ans:"You will get take away hardware kit but if have your own and don’t want hardware kit from us so we will deduct the hardware cost from total training cost."
  },
  {
    id:"8",
    qa:"What are the system requirements for this IoT Certification Training ? ",
    ans:<div><p><b>Hardware Requirement(s):</b></p><br/>
    <ul>
        <li>Raspberry Pi 3 Model B</li>
        <li>Sense-Hat</li>
        <li>SD card (minimum 16Gb class 10 recommended)</li>
        <li>USB Keyboard and Mouse</li>
        <li>Display Unit (Monitor or TV with HDMI support)</li>
        <li>HDMI to VGA converter (optional)</li>
        <li>USB Mic and Speaker</li></ul><br/>
        <p><b>Software Requirement(s): </b></p><br/>
        <ul>
          <li>Google Developers account Google Developers Console, which is free</li>
          <li>Amazon Developers account developer.amazon.com, which is free</li>
        </ul>
        </div>
  },
]


 
const Contact = () => {
    
    return (
        <div id="contact">
        <span  style={{marginTop:"40px"}}></span>
        <Container>
        <h2 className="faq-title mt-5 mb-3">FAQ</h2>
        {FaqData.map(item=> (
        <Accordion className="faq-accordion" key={item.id}>
        <Card >
            <Card.Header className="faq-card-header" >
              <Accordion.Toggle  as={Card.Header} variant="link" eventKey={item.id}>
             {item.qa}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={item.id}>
              <Card.Body className="faq-card-body" >{item.ans}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        ))}

      </Container>   
        </div>
    )
}

export default Contact;
