import React,{useState} from 'react';
import {Modal,Form,InputGroup} from 'react-bootstrap';
import {FaUser,FaPhoneAlt,FaEnvelope,FaEdit} from 'react-icons/fa';
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import './Book.css';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_q2onGdIpd5vItAyieytfr"); 


//phone number regExp
const PhoneregExp=  /^[6789]\d{9}$/;
//validation schema for enquiry form
const ValidationSchema = yup.object().shape({
  name: yup.string().max(20).required("Please enter your name"),
  number: yup.string().matches(PhoneregExp,'Please enter a valid number').required("Contact number is required"),
  email: yup.string().email("Please enter a valid Email address").required("Please enter your email"),
  messagetext:yup.string().required("Describe your message")
});

const BookForm = () => {
 
  const [showModal, setShowmodal] = useState(false);

  const handleCloseBox = () => setShowmodal(false);
 

  const [showSnackbar,setShowSnackbar]=useState(false);
  const [showError,setShowError]= useState(false);
   const { register, handleSubmit,errors } = useForm({ mode: 'onSubmit',
   reValidateMode: 'onChange',
   defaultValues: {},
   // Note: will be deprecated in the next major version with validationResolver
   resolver: yupResolver(ValidationSchema),
   validateCriteriaMode: "firstErrorDetected",
   submitFocusError: true,
   nativeValidation: false, // Note: version 3 only
  }) 


    const onSubmit = (data, r) => {
        r.preventDefault();
        const templateId = 'template_kuxu8zv';
        const serviceID = 'service_j2wqtjs';
        sendFeedback(serviceID, templateId, { from_name: data.name,contact_number:data.number,reply_to: data.email, message_html: data.messagetext})
        r.target.reset();
    }
    const sendFeedback = (serviceID, templateId, variables) => {
        emailjs.send(
            serviceID, templateId,
            variables
        ).then(res => {
           setShowmodal(false);
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
          },3000);
        })
            .catch(err =>{
              setShowError(true);
              setTimeout(() => {
                setShowError(false);
              }, 5000);
            })
    } 


    return (
        <>
        <button className="fixedRight-button" onClick={()=>setShowmodal(true)} >Book for Demo</button> 
          <Modal className="demo-modal mt-3"
        show={showModal} 
        onHide={handleCloseBox}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton className="text-center bg-light font-weight-bolder" >
          Feel free to Contact us 
        </Modal.Header>
        <Modal.Body>
         <div className="p-2">
         <Form  onSubmit={handleSubmit(onSubmit)}> 
         <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-boxB"><FaUser/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control  placeholder="Name" type="text" name="name"  ref={register} />
        </InputGroup>
  
        <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-boxB"><FaPhoneAlt/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control  placeholder="Mobile Number" type="number" name="number" ref={register}  />
        </InputGroup>

        <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-boxB"><FaEnvelope/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control placeholder="Email" type="email" name="email" ref={register}  />
        </InputGroup>

        <InputGroup className="mb-3">
        <InputGroup.Prepend><InputGroup.Text className="input-boxB"><FaEdit/></InputGroup.Text></InputGroup.Prepend>
        <Form.Control  placeholder="Your Message" type="textarea" name="messagetext" ref={register}  />
        </InputGroup>
         <div className="error-boxB">
         {errors.name &&<p><span>*</span>{ errors.name.message}</p>}
         {errors.number &&<p><span>*</span>{errors.number.message}</p>}
        {errors.email && <p><span>*</span>{errors.email.message} </p>}
            {errors.messagetext && <p><span>*</span>{errors.messagetext.message}</p>}
         </div> 
         <div className="text-center">
           <div className="mt-1 mb-1">
             { showError ? 
             <h5><b>Something went wrong,<br/> Please try after sometime</b></h5> : null
              }
           </div>
         <input className="submitBtn3" type="submit" value="Book for Demo"/>
         </div>
         </Form>
         </div>
        </Modal.Body>
      </Modal> 
        <div className={showSnackbar ? "snackBar show" : "snackBar"}>
          Your Response is Submitted!
        </div>
        </>
    )
}

export default BookForm;
