import React,{useState,useEffect,useCallback} from 'react';
import { NavHashLink } from "react-router-hash-link";
import {FaBars,FaTimes} from 'react-icons/fa';
import Logo from '../../assets/logo.png';
import './Nav.css';
const Nav = () => {
    const [click, setClick] = useState(false);
    const [scroll,setScroll]= useState(false);


  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const handleScrollMenu = useCallback(()=>{
    const offset= window.scrollY;
    if(offset>200){
        setScroll(true);
    }else{
        setScroll(false);
    }
},[]);
useEffect(()=>{
    window.addEventListener('scroll',handleScrollMenu);
    return ()=>window.removeEventListener('scroll',handleScrollMenu);
});

let navbarClass=['navbar'];
if(scroll){
    navbarClass.push('scroll');
}
const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; 
    window.scrollTo({ top: yCoordinate + yOffset,}); 
}

    return (
        <div>
            <nav className={navbarClass.join(" ")}>
        <NavHashLink  to='/#home' className='navbar-logo' onClick={closeMobileMenu}>
        <img src={Logo} alt=""/>
        </NavHashLink>
        <div className='menu-icon' onClick={handleClick}>
        { click ? <FaTimes/> : <FaBars/>}
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li className='nav-item'>
        <NavHashLink  to='/#about'  scroll={el => scrollWidthOffset(el)}  className='nav-links' onClick={closeMobileMenu}>
            About
        </NavHashLink>
        </li>
        <li className='nav-item'>
        <NavHashLink  to='/#course' scroll={el => scrollWidthOffset(el)} className='nav-links' onClick={closeMobileMenu}>
            Course
        </NavHashLink>
        </li>
        <li className='nav-item'>
        <NavHashLink  to='/#contact' scroll={el => scrollWidthOffset(el)} className='nav-links' onClick={closeMobileMenu}>
            FAQ
        </NavHashLink>
        </li>
        </ul>
        <div className="contactBar">
        <a href="tel:+918489900115">
            <span><b>Call us:</b> (91)8489900115</span>
            </a><br/>
            <a href ="mailto:iottrainingincbe@gmail.com">
            <span ><b>Mail:</b> iottrainingincbe@gmail.com</span>
            </a>   
        </div>
        </nav>
        </div>
    )
}

export default Nav;
