import React from 'react';
import {Row,Col} from 'react-bootstrap';
import starfive from '../../../assets/5star.jpg';
import starfour from '../../../assets/4.5.jpg';
import './Reviews.css';

const reviewData=[
    {
        id:"1",
        imgSrc:starfive,
        name:"Sathish",
        review:"I have completed my IOT training in Coimbatore. It was a wonderful experience since they gave us work environment as like we were working in top organizations. They taught us at completely different level and not only just teaching but they gave us some hands-on in order to understand the importance of each topic we discussed. And their support when we come through any issues was even better. So if any one wants to learn any trending technologies, then Iot training in Coimbatore is the best choice."
    },
    {
        id:"2",
        imgSrc:starfour,
        name:"Shanmathi",
        review:"I am impressed with the way of approach the team IOT Training in Coimbatore has towards IOT Training, I had a good experience doing my internship here, The institute has helped me to build a good perception on the concepts and most importantly they are a professional team who handles projects on Marketing for companies from different industries in real time, which provided me an environment to deal with many used cases they record.. Recommended !"
    },
    {
        id:"3",
        imgSrc:starfive,
        name:"Sethu Pathy",
        review:"I am impressed with the way of approach the team IOT Training in Coimbatore has towards IOT Training, I had a good experience doing my internship here, The institute has helped me to build a good perception on the concepts and most importantly they are a professional team who handles projects on Marketing for companies from different industries in real time, which provided me an environment to deal with many used cases they record.. Recommended !"
    },
    {
        id:"4",
        imgSrc:starfive,
        name:"Siva Sbk",
        review:"Awesome, done course in IOT here. it was 2 months, best part is they have limited number of students in every batch and they give more attention to each individual. Trainers are very good and experienced Faculty.Thank You IOT Training in Coimbatore."
    },
    {
        id:"5",
        imgSrc:starfour,
        name:"Thangaraj",
        review:"I came here to learn about IOT Course Training. This is the best institute if you want to make yourself better. The best part is, the classes won't be like someone standing there and giving lectures, there will be interaction asking for doubts giving tasks, if we are not comfortable with anything we can freely discuss with the trainee."
    },
    {
        id:"6",
        imgSrc:starfive,
        name:"Narmatha N",
        review:"IOT is best course to fresher as well as for experienced too.. it give you path to your career to build better future.so one of my friend suggested me to take this course based on my requirement and after joining here the support which I got from trainer its was awesome. while completion of my course I start building wonderful applications and also exploring my ideas .It is a best place to get trained and they not only train you but also help in interviews to present yourself while giving interviews Thanks IOT Training in Coimbatore and team."
    },
]


const Reviews = () => {
    return (
        <div className="review-box">
        <br/>
        <div className="description-content p-1">
            <ul className="pl-0">
                <li style={{listStyle:"none"}}>
                    <Row>
                        <Col lg={12}>
                            {reviewData.map(val=>
                            <div className="pl-0 ml-2 review-content" key={val.id}>
                                <img className="img-fluid pull-right" src={val.imgSrc} alt=""/>
                                <div>
                                    <p><b>{val.name}</b></p><br/>
                                </div>
                                <div>
                                    <p>{val.review}</p>
                                </div>
                            </div>
                            )}
                        </Col>
                    </Row>
                </li>
            </ul>
        </div>
        </div>
    )
}

export default Reviews;
