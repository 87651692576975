import React from 'react'
import {Col} from 'react-bootstrap';
import './Features.css';
const Features = (props) => {
    return (
        <>
            <Col xs={6} sm={6} md={4} lg={4} className="features-col text-center">
              <img src={props.imgSrc} alt=""/>
              <p className="features-title">{props.title}</p>    
            </Col> 
        </>
    )
}

export default Features;
