import React from 'react';
import './Syllabus.css';
const Syllabus = () => {
    return (
        <div className="syllabus-box">
        <div className="syllabus-box-wrap">
        <div className="syllabus-content">
        <h4 className=" mt -1 mb-1">
        Internet of Things (IoT) Course : Syllabus
        </h4>
        <h4 className="mt-1 mb-1">1.Introduction and IoT Fundamentals</h4>
        <ul className="mt-1 mb-1">
            <li>Why IoT</li>
            <li>What is IoT</li>
            <li>How IoT works</li>
            <li>Use Cases Smart Home, Smart City, Industrial</li>
        </ul>
        <h4 className="mt-1 mb-1">2.Elements of IoT and Architecture</h4>
        <ul className="mt-1 mb-1">
            <li>Architecture of IoT</li>
            <li>Hardware</li>
            <li>Software</li>
        </ul>
        <h4 className="mt-1 mb-1">3.Technology Stack</h4>
        <ul className="mt-1 mb-1">
        <li>Sensors</li>
        <li>Edge Devices</li>
        <li>cloud</li>
        <li>Field/Edge Network</li>
        <li>Gateway/Control Room Network</li>
        <li>Communication Protocols</li>
        <li>Data Streaming</li>
        <li>Data Storage</li>
        <li>Data Analytics</li>
        <li>Data Visualization</li>
        <li>Security</li>
        <li>Artificial Intelligence</li>
        <li>Wired/Wireless Communication</li>
        <li>Powered/Battery Operated Devices</li>
        <li>Constrained Devices</li>
        </ul>
        <h4 className="mt-1 mb-1">4.Edge Devices & Gateway Devices With hands-on using Arduino& ESP32 (intro) & Raspberry PI using Node.js</h4>
        <ul className="mt-1 mb-1">
            <li>Introduction to Edge Devices</li>
            <li>Wired, Wireless Communications</li>
            <li>Serial Port/UART</li>
            <li>BLE/WIFI</li>
            <li>Introduction to Arduino [Serial port communication]</li>
            <li>Introduction to ESP32 [WIFI/BLE Device] (Micro Controller for Edge Devices)</li>
            <li>Hands-on using C [Arduino], Embedded JavaScript [ESP]</li>
        </ul>
        <h4 className="mt-1 mb-1">5.Sensors and Fundamentals with Hands-on lab Node.js/Raspberry PI</h4>
        <ul className="mt-1 mb-1">
            <li>Sensing and Measurements</li>
            <li>0-5 Voltage</li>
            <li>Analog I/O</li>
            <li>Pulse Width Mode</li>
            <li>I2C Communication</li>
            <li>Sensor Types, Classification</li>
            <li>Visual, Fleet Tracking sensors</li>
            <li>Wiring Basics</li>
            <li>Practical:</li>
            <li>Working with Temperature, Humidity, Light & Motion Detector, Promity Sensor</li>
        </ul>
        <h4 className="mt-1 mb-1">6.Actuators and Controllers with Hands-on using Raspberry PI with Node.js</h4>
        <ul className="mt-1 mb-1">
            <li>Actuators and Controllers</li>
            <li>Controllers Introduction</li>
            <li>Buzzer</li>
            <li>Relay Switches</li>
            <li>Servo Motors</li>
            <li>Practical:<br/>
            Buzzer on/off <br/>
            Relay switch on/off light<br/>
            Light brightness adjustment
            </li>
            <li>Working with BLE Devices</li>
            <li>Introduction to Bluetooth and 2.4 ghz spectrum</li>
            <li>BLE, Bluetooth Smart</li>
            <li>BLE communication and pairing</li>
            <li>Serial over Bluetooth</li>
            <li>Securing Bluetooth communication</li>
            <li>GAP(Advertising and Connections)</li>
            <li>GATT(Services and Characterstics)</li>
            <li>Topology</li>
            <li>BLE stack analysis</li>
            <li>Edge to Gateway communication using Bluetooth</li>
            <li>Use cases for Bluetooth</li>
            <li>BLE vs WIFI Power consumption</li>
            <li>Hands-on Edge Device (ESP32) talks to Gateway (Raspberry PI) using BLE communication</li>
        </ul>
        <h4 className="mt-1 mb-1">7.MQTT</h4>
        <ul className="mt-1 mb-1">
            <li>MQTT Introduction</li>
            <li>Brokers</li>
            <li>Publish/Service</li>
            <li>Topics</li>
            <li>QOS [0, 1, 2 levels]</li>
            <li>MQTT Message Format</li>
            <li>Messaging, Ack format</li>
            <li>Payload</li>
            <li>Security [TLS, User Authentication]</li>
            <li>MQTT Authorization</li>
        </ul>
        <h4 className="mt-1 mb-1">8.MQTT Lab [WIFI, TCP/IP] with Mosquitto</h4>
        <ul className="mt-1 mb-1">
            <li>Using Mosquitto MQTT Broker</li>
            <li>MQTT Publisher, Subscriber</li>
            <li>Designing Scalable Architecture</li>
            <li>Measuring and Controlling over MQTT</li>
            <li>Hands on using Node.js/Java/C#/Python based on training needs</li>
            <li>Gateway with Raspberry PI</li>
        </ul>
        <h4 className="mt-1 mb-1">9.Gateway Introduction</h4>
        <ul className="mt-1 mb-1">
            <li>Needs for Gateway, Roles of Gateway</li>
            <li>Edge/Gateway Connectivity</li>
            <li>Raspberry PI, Single Board Linux Computer</li>
            <li>WIFI/BLE Communication with Edge Devices</li>
            <li>Hands on using Node.js/Java/C#/Python based on training needs</li>
        </ul>
        <h4 className="mt-1 mb-1">10.Constrained Devices and IEEE 802.15.4, Communication, protocols</h4>
        <ul className="mt-1 mb-1">
            <li>Constrained Devices Fundamentals</li>
            <li>Understanding 802.15.4 standards, Application protocols, gateways for constrained devices,</li>
            <li>Radio Freq [BLE, ZigBeeetc]</li>
            <li>Power constraint, resource and memory constrained devices</li>
            <li>Understanding payload</li>
            <li>IPv6, UDP, TLS, DTLS</li>
            <li>CoAP introduction (Constrained Application Protocol), HTTP RESTful like, small foot print, binary level defined by Internet Engineering Task Force (IETF), a standard body behind HTTP and numerous internet protocols) Security discussion using TLS, DTLS</li>
        </ul>
        <h4 className="mt-1 mb-1">11.CoAP hands-on practice</h4>
        <ul className="mt-1 mb-1">
            <li>Working with CoAP protocol using ESP32</li>
            <li>Send receive messages using CoAP protocols</li>
            <li>Hands-on involves Embedded JS and Java/Node.js</li>
        </ul>
        <h4 className="mt-1 mb-1">12.LoRA, LoRAWAN - Smart City Use Cases</h4>
        <ul className="mt-1 mb-1">
            <li>Working with Smart City Solutions</li>
            <li>Problem understanding</li>
            <li>Introduction to LoRA</li>
            <li>LoRA Hardware and bandwidth</li>
            <li>Communication between Lora Devices, LoRA Gateway, LoRAWAN</li>
            <li>WIFI vs BLE vsZigBeevsLoRA</li>
        </ul>
        <h4 className="mt-1 mb-1">13.Firmware Upgrade over Air (OTA)</h4>
        <ul className="mt-1 mb-1">
            <li>Upgrade device firmware over the air</li>
            <li>Designing APIs for future devices, revisions</li>
        </ul>
        <h4 className="mt-1 mb-1">14.IoT and Cloud</h4>
        <ul className="mt-1 mb-1">
            <li>IoT and Cloud introduction</li>
            <li>Data ingestion using MQTT</li>
            <li>Understanding Device Management</li>
            <li>Device Security</li>
            <li>Device Connectivity</li>
        </ul>
        <h4 className="mt-1 mb-1">15.AMQP Introduction</h4>
        <ul className="mt-1 mb-1">
            <li>MQTT to AMQP Bridge</li>
            <li>Cloud Integration: Azure or AWS IoT [developer preference]</li>
        </ul>
        </div>            
        </div>
        </div>
    )
}

export default Syllabus;
