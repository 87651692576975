import React from 'react'

const Overview = () => {
    return (
        <div className="mt-2" style={{   margin: "0 0 40px 0",
            borderBottom:" 1px solid #dee2e6",
            borderLeft:" 1px solid #dee2e6",
            borderRight: "1px solid #dee2e6"}}>
            <p className="mt-1 mb-2 p-5" style={{textAlign:"justify"}}>
            With the best IoT courses in coimbatore, the curriculum is designed by our expert team which follows a standardized approach. The Iot Training in Coimbatore  emphasizes on learning through real-world data problems to help you gain not just theoretical but also practical knowledge.
            </p>
        </div>
    )
}

export default Overview;
