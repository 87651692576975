import React from 'react'
import {Container,Row,Col,Jumbotron} from 'react-bootstrap';
import footerLogo from "../assets/footerlogo.png";
import {FaEnvelope,FaPhone} from 'react-icons/fa';
import './Footer.css';
const Footer = () => {
    return (
        <>
        <div className="mainFooter">
        <Container fluid className="mt-5 footer-sub  text-center text-white">
            <Row className="mt-3 mb-3">
                <Col xs={12} sm={12} md={4} lg={4}>
                <img  src={footerLogo} width="200" height="200" alt=""/>
                </Col>
                <Col className="mt-4 mb-1" xs={12} sm={12} md={4} lg={4}>
                    <h4>Contact-Us</h4>
                <h5>Address:</h5>
                <p>First Floor:No:22,<br/>
                Sarojini Street,
                Ram Nagar,<br/>
                Coimbatore,<br/>
                TamilNadu-641009.</p>
                <div className="mt-2 mb-2">
                <a href="https://www.google.com/maps/place/IoT+Training+in+Coimbatore+-+Live+Project+Real+Time+Training%7C+Industrial+Projects+%7C/@11.0111375,76.9636323,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba8591ba4d025d7:0xfb6203b630470ae4!8m2!3d11.0111375!4d76.965821">
                <button className="pressDownButton">Locate Us </button>
                </a>
                </div>
                </Col>
                <Col className="mt-4 mb-1" xs={12} sm={12} md={4} lg={4}>
                <div>
                <h5><span className="mr-1 p-1"><FaEnvelope/></span>Email :</h5>
                <p>iottrainingincbe@gmail.com</p>
                </div>
                <div>
                <h5><span className="mr-1 p-1"><FaPhone/></span>Contact Number :</h5>
                <p>+91-84899 00115</p>
                <div className="mt-2 mb-2">
                <a href="tel:+918489900115">
                <button className="pressDownButton">Call us</button>
                </a>
                </div>
                </div>
                </Col>
            </Row>
        </Container>
        </div>
        
        <Jumbotron  id="footer-sub" >
            <Row>
                <Col className="text-center" xs={12} sm={12} md={12} lg={12}>
                <p style={{color:"#333",fontWeight:"500"}}>Copyright &copy;{new Date().getFullYear()} ||<span style={{color:"#166586"}}> 
                IOT Training-Coimbatore</span></p>
                </Col>
            </Row>
        </Jumbotron>
        </>
    );
}

export default Footer;
