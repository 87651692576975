import React from 'react';

const ProgramDetails = () => {
    return (
        <div className="mt-3 p-2" style={{margin: "0 0 40px 0",
        borderBottom:" 1px solid #dee2e6",
        borderLeft:" 1px solid #dee2e6",
        borderRight: "1px solid #dee2e6"}}>
        <div className="pl-1 pr-1">
        <ul style={{listStyle:"disc",color:"#333",marginLeft:"10px",paddingLeft:"10px",marginBottom:"0",textAlign:"justify"}}>
        <li style={{marginBottom:"10px"}}>100 % Job Placement: This IoT training in Coimbatore is completely job-oriented training. Our trainers will assist you with placement support and guarantee and will be with you till you get placed in the best and top companies in the IT sector.</li>
        <li style={{marginBottom:"10px"}}>Trainers: The instructors are highly skilled professionals in the relevant domain. They have best of industry knowledge with years of experience working in real world data projects. And this is what makes us the Best IoT training institute in Coimbatore.</li>
        <li style={{marginBottom:"10px"}}>Syllabus: The course curriculum is designed in such as way to enhance your theoretical as well as practical knowledge about IoT. We cover all the basics topics in IoT such as Introduction to IOT and IOT Communication Layer, Cloud, sensors and many more.</li>
        <li style={{marginBottom:"10px"}}>Infrastructure: The facilities at Iot training in Coimbatore provides a fast paced learning.We provide advanced lab facilities and infrastructure to give you best learning environment.</li>
        <li style={{marginBottom:"10px"}}>Student free benefits: The other benefits include regular practical assignments and projects, free mock tests and interview preparation guidance and support, resume preparation guide, etc.</li>
        </ul>
        </div>
        </div>
    )
}

export default ProgramDetails;
