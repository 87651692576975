import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container,Row,Col} from 'react-bootstrap';
import Nav from './Components/Nav/Nav';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Course from './Components/Course/Course';
import Contact from './Components/Contact/Contact';
import RequestForm from './Components/Form/Request/RequestForm';
import DemoForm from './Components/Form/Demo/DemoForm';
import BookForm from './Components/Form/Book/Book';
import Footer from './Components/Footer';
import BTot from './Components/BTot';

function App() {
 
  return (
    <div className="App">
 
      <Nav/>
    <Container>
      <Row className="pr-0">
      <Col xs={12} sm={12} md={8} lg={8}>
     <Home/>
     <About/>
     <Course/>
     <DemoForm/>
     <Contact/>
     <RequestForm />
     <BookForm/>
     </Col>
     </Row>
     </Container>
     <div className="mb-n2">
    <BTot/>
     <Footer/>
     </div>
    </div>
  );
}

export default App;
