import React from 'react';
import exactIot from '../../assets/exactIot.png';
const tabsAbout=[
    {
        id:"1",
        label:"What is IoT?",
        description:"Internet of things -IOT is a computing technology that operates between the functions of information and operation technology. It is the combination of computing devices and digital machines. It is a growing field and student can find a wide range of opportunities having a IoT Certification in Coimbatore. To help you master the skills of allocating data over the networks, Iot Training in Coimbatore provides the best IoT Training in Coimbatore."

    },
    {
        id:"2",
        label:"What exactly is the Internet of Things?",
        description:<div><p>The Internet of Things (IOT) is the next wave, world is going to witness. Today, we live in an era of connected devices (mobile phones, computers etc.), the future is of connected things (Things mean: home appliances, vehicles, lamp-posts, personal accessories, your pets, industrial equipment’s and everything which you use in day to- day life). Internet of Things is a term given to the attempt of connecting objects to the internet and also to each other - allowing people and objects themselves to analyze data from various sources in real-time and take necessary actions in an intelligent fashion.</p><br/> <img width="100%" src={exactIot} alt=""/></div>,

    },
    {
        id:"3",
        label:"What are the objectives of our IoT Course?",
        description:<div><p>IoT Certification Training is designed by industry experts to make you a Certified IoT Developer. This IoT course offers: </p><br/>
                    <ul>
                        <li>Understanding of IoT architecture and IoT Decision Framework</li>
                        <li>Configure Raspberry Pi, Understand Sensors, Actuators & get started with python on Raspberry Pi</li>
                        <li>Program Sensors (Sense-HAT board), Face Detection and reorganization using OpenCV-Python</li>
                        <li>Understand various IoT Networking Protocols which are mainly used to develop communication solutions</li>
                        <li>Getting started with Cloud and Azure IoT Hub, demonstration of AWS IoT Hub APIs</li>
                        <li>Architect an End-to-End Solution using Raspberry Pi, Sense HAT and AWS</li>
                        <li>Perform Data Analytics on the collected sensor data, use prediction algorithms to get useful insights</li>
                        <li>IoT challenges, business solutions, research scope and current development, Alexa Voice Bot on Raspberry Pi</li>
                    </ul>
    </div>
    },
    {
        id:"4",
        label:"What are the skills that you will be learning with our IoT Certification Training?",
        description:<div><p>IoT Certification Training will help you to become an IoT Developer. It will hone your skills by offering you comprehensive knowledge on IoT. It will also acquaint you with the required hands-on experience for solving real-time industry-based IoT projects. During this course you will be trained by our expert instructors on:</p><br/>
        <ul>
        <li>Architecting an IoT Solution</li>
        <li>Programming Sensors</li>
        <li>IoT Networking Protocols</li>
        <li>AWS IoT Hub and its services</li>
        <li>Working with REST APIs</li>
        <li>Data Analytics in IoT</li>
        <li>IoT Business Designer</li>
        </ul>
        </div>
    },
    {
        id:"5",
        label:"What are the pre-requisites for this course?",
        description:<ul><li>Basic Computer Knowledge</li><li>Knowledge of C/C++/Embedded would be beneficial, however not mandatory</li></ul>
     },
    {
        id:"6",
        label:"Why IoT training in Coimbatore ",
        description:<ul style={{listStyle:"none"}}>
            <li>Because Iot training in Coimbatore provides an excellent IoT Classroom training in coimbatore as well as IoT online training in Coimbatore. We help students mold their career enthusiastically and skillfully to get placed in the right company with the right set of skills and knowledge.</li> <hr/>
            <li>We also offer a fun learning environment for students to enhance learning experience. We provide 100% placement guarantee and support for students until they get placed in one of the Top companies worldwide. Our syllabus covers all the topics that are required to build your knowledge about Internet of Things (IoT). At the end, we also provide you a course completion certificate that will a important tool to high paid job. We have also done training to many students and have shaped their career in the best way.</li><hr/>
            <li>Join in today to get the best IoT training in Coimbatore </li>
        </ul>
    },
]
export default tabsAbout;