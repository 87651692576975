import React,{useState} from 'react';
import {Tabs,Tab,Container} from 'react-bootstrap';
import Syllabus from './Content/Syllabus';
import Overview from './Content/Overview';
import ProgramDetails from './Content/ProgramDetails';
import Reviews from './Content/Reviews';
import courseContent from '../../assets/courseContent.png';
import './Course.css';
const Course = () => {
    const [key,setKey] = useState('syllabus')
    return (
        <div id="course">
             <span  style={{marginTop:"40px"}}></span>
             <Container>
             <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k)=>setKey(k)}>
             <Tab eventKey="syllabus" title="Syllabus">
                 <Syllabus/>
            </Tab>
            <Tab eventKey="overview" title="Overview">
                <Overview/>
            </Tab>
            <Tab eventKey="programdetails" title="Program Details">
                <ProgramDetails/>
            </Tab>
            <Tab eventKey="reviews" title="Reviews">
                <Reviews/>
            </Tab>
             </Tabs>
             <div className="mt-5 mb-4">
                <div className="course-benefit">
                    <h3>Benefits of IOT Training Course in Coimbatore</h3>
                    <ul>
                        <li>IoT technology and tools</li>
                        <li>Core concepts and background technologies</li>
                        <li>Features of the IoT landscape</li>
                        <li>Sensors, microcontrollers and communication interfaces to design and build IoT devices</li>
                        <li>Designing and building a network based on the client server</li>
                        <li>Publishing/subscribing to connect, collect data, monitor and manage assets</li>
                        <li>Writing device, gateway and server-side scripts and apps, enabling them to aggregate and analyse sensor data</li>
                        <li>Selecting application-layer protocols and web services architectures for a seamless integration of various components within an IoT ecosystem</li>
                        <li>Reviewing standard development initiatives and reference architectures</li>
                        <li>Deploying various types of analytics on machine data to define context, find faults, ensure quality and extract valuable actionable insights</li>
                        <li>Cloud infrastructure, services, APIs and architectures of commercial and industrial cloud platforms</li>
                        <li>Prevalent computing architectures, including distributed, centralized, and edge/fog computing</li>
                        </ul>
                </div>
             </div>
             <div className="mt-5 mb-4 text-justify">
                 <div className="course-benefit">
                     <h3>Placement of IOT Training in Coimbatore</h3>
                     <p className="">The<span className="course-highlight"> IoT training in Coimbatore</span> will open many doors for stepping into the world of opportunities where the physical world meets the digital world. The IoT trends to look out for are the rise in the demand for connected home devices, Edge computing that will offer benefits like better management of large amounts of data, lower dependency on cloud and providing better security of data. IoT will also mark a golden period for retailers and marketers who with the help of beacon and sensors will be able to offer next-level service complimented by greater personalization. Sectors like Healthcare and Education will also be able to deliver value offerings to their end customers. Technologies like Big Data, Artificial Intelligence, and Machine Learning will dictate and define drift and involvement of IoT in the coming time.</p>
                    <br/>
                    <p>With the growth in the rapidly expanding field of IoT, the salaries of IoT professionals in India are said to be 80% higher than that of other IT professionals. The average salary of a mid-level IoT professional is around 16 lakh per annum which is pretty impressive as compared to other IT sectors. With top companies like Accenture, Amazon, Wipro, Oracle, Infosys extensively hiring IoT professionals only suggests an optimistic future for people looking to pursue a career in IoT. Gear up for a successful career in this future technology by getting enrolled in the<span className="course-highlight"> IoT Training in Coimbatore</span> which is provided as per Industry-based standards and delivered to you by expert trainers from the industry which is an added advantage of this training in Coimbatore.</p>
                 </div> 
             </div>
             <div className="course-content-pic mt-3 mb-1">
                         <img  src={courseContent} width="100%" alt=""/>
                     </div>
             <div className="mt-5 mb-4">
                 <div className="course-benefit">
                     <h3>Job Opportunities & Application of IoT Training in Coimbatore:</h3>
                     <ul>
                         <li>4.5 Million Jobs to be generated for IOT’s trained professionals in top companies by 2019 (33 billion objects will be connected by 2019).</li>
                         <li>90 % of Top electronics products companies like (Intel, Qualcomm, apple, IBM, Microsoft, Samsung, whirl-pool, L.G., Amazon, Daikin) are going to be smart.</li>
                         <li>And the concepts behind Smart Homes, Smart watches, Smart cities etc are nothing but IOT (Internet of Things) Which can be controlled or monitored via any remote location through mobile or web].</li>
                         <li>Government of India has allocated 7,060 crores for 100 new smart cities in Budget 2018-2019</li>
                     </ul>
                 </div>
             </div>
             </Container>
        </div>
    )
}

export default Course;
