import React from 'react';
import {Container,Accordion,Card} from 'react-bootstrap';
import tabsAbout from './TabsData';
import aboutinfo from '../../assets/about.png';
import DemoForm from '../Form/Demo/DemoForm';

import './About.css';

const whatGet=[
  {
    id:"1",
    text:"Design independent IoT devices for different industrial sectors"
  },
  {
    id:"2",
    text:"Comprehend IoT architecture and modern micro-controllers for Data Capture and Signal Relaying"
  },
  {
    id:"3",
    text:"Configure an HTTP server and develop python modules for basic data analytics"
  },
  {
    id:"4",
    text:"Leverage different cloud platforms and services like IAAS, PAAS, and SAAS"
  },
  {
    id:"5",
    text:"Hands-on training on the Raspberry Pi Board"
  },
  {
    id:"6",
    text:"Understand IoT communication protocols"
  },
]

const About = () => {
    return (
        <div id="about">
          <span  style={{marginTop:"40px"}}></span>
          <Container className="about-title mt-2  mb-1">
            <div className="about-info">
            <h2 >About IOT Training in Coimbatore</h2>
            <p className="text-justify">This <span className="about-highlight">course on IoT in Coimbatore</span> aims to provide an overview of the IoT ecosystem, various technologies used in IoT, and understanding the standards related to the Internet of Things. The various tools covered in this course are Raspberry and Arduino. A separate module has been designed to explain the fundamentals of Embedded C and python which is clubbed with hands-on exposure on different kinds of embedded development boards like Raspberry Pi.</p>
            <img  src={aboutinfo} className="mt-4 mb-3 p-4" alt=""/>
            </div>
     {tabsAbout.map(tab => (
        <Accordion  className="pb-1 text-left  w-100" key={tab.id} defaultActiveKey="1">
          <Card >
            <Card.Header className="about-card-header" >
              <Accordion.Toggle  as={Card.Header} variant="link" eventKey={tab.id}>
             {tab.label}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={tab.id}>
              <Card.Body className="about-body" >{tab.description}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
     ))}
    
     <div className="mt-5 mb-4 what-get">
       <div>
       <h2 className="mt-2 mb-2">What will you learn</h2>
       </div>
      <ul>
      {whatGet.map(val=>(
      <li key={val.id}>{val.text}</li>
      ))}
      </ul>
     </div>
     <div className="mt-5 mb-4 text-center">
       <div className="what-get">
         <h4 className="offer-title mt-3 mb-3">GET FREE LIVE DEMO ON IOT FROM INDUSTRY EXPERTS!</h4>
         <h5 className="offer-title2 mt-2 mb-3">ClassRoom & Online Training</h5> 
         <div className="mt-2">
         <DemoForm/>
           </div>
       </div>
     </div>
     </Container>
        </div>
    )
}

export default About;
