import React from 'react';
import banner from '../../assets/banner.png';
import lifetime from '../../assets/keyFeatures/Lifetime.png';
import realtime from '../../assets/keyFeatures/Realtime.png';
import cloudlab from '../../assets/keyFeatures/cloudlab.png';
import dayweek from '../../assets/keyFeatures/24X7.png';
import moneyback from '../../assets/keyFeatures/moneyback.png';
import feedback from '../../assets/keyFeatures/feedback.png';
import Features from './Features';
import {FaStar} from 'react-icons/fa';
import {Container,Row} from 'react-bootstrap';

import './Home.css';


const keyFeatures=[
    {
        index:2,
        imgSrc:lifetime,
        title:"Lifetime Access"
    },
    {
        index:3,
        imgSrc:realtime,
        title:"Realtime Code Analysis"
    },
    {
        index:4,
        imgSrc:cloudlab,
        title:"Cloud Labs"
    },
    {
        index:4,
        imgSrc:dayweek,
        title:"24 X 7 Support"
    },
    {
        index:5,
        imgSrc:moneyback,
        title:"Money Back"
    },
    {
        index:6,
        imgSrc:feedback,
        title:"Project Feedback"
    }
]

const Home = () => {
    return (
        <div id="home">
        <Container  className=" mb-4">
        <img className="bannerImg mt-5 mb-2" src={banner} alt=".."/>
        <h2 className="banner-title mt-4 mb-2">Best Internet of Things Training Courses(iot)</h2>
        <p className="banner-text text-justify">The <span className="highlight-home">IoT training in </span>Coimbatore commences with an overview of the concepts and various applications of the Internet of Things (IoT). This IoT course will enable you to grasp the fundamental concepts of IoT and its applications. Knowledge of IoT Network Architecture, Device Architecture and Client-Server Architecture is disseminated. The various types of sensors and Embedded Development Boards like Arduino, Raspberry Pi are elaborated. The various IoT communication protocols (wired, wireless, application, and transport layer) are dealt with in detail. Training on Cloud Computing and Cloud services like IaaS, PaaS, SaaS is imparted. Students will learn to design an IoT product, interface peripherals, and program GPIOs, I/O peripherals, and sensor modules. Python Programming for a Raspberry-Pi Board and hands-on training on a Raspberry-Pi board are also key topics on the agenda. All this and more make this program the<span className="highlight-home"> best IoT course in Coimbatore</span>.</p>
        <div className="rating">
            <div className="badge badge-box mr-3">
                <span>Beginner</span>
            </div>
            
            <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/> 
            <span className="d-inline-block ml-1">4.78</span>
            <span className="">No. of Ratings: 66</span>
            <span className="ml-2 badge-enroll">No. of Enrollments:78</span>
        </div>
        <div className="features-col">
            <h4 style={{color:"#333"}} className="mt-5 " >Key Features</h4>
        <div className="mt-2 mb-4">
                <Row >
                    {keyFeatures.map((val,ind)=>(
                    <Features  key={ind}
                      imgSrc={val.imgSrc} 
                      title={val.title}
                      />
                        ))}
                </Row>
                </div>
                </div>
            
            </Container>   
        </div>
    )
}

export default Home;
